import React, {useEffect, useState}  from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import SEO from '../components/seo'
import Layout from '../components/layout';

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';

const Hero = importedComponent(() => import('../components/Hero'));
const ReferenceHandler = importedComponent(() => import('../components/ReferenceHandler'));

const slugify = require('slugify');

function createMarkup(vr360) {
  return {
    __html:
      `<iframe src=${vr360} width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" />`,
  };
}

const RegularPage = ({ data, pageContext, location }) => {
  const [baronaJobs, setBaronaJobs] = useState([]);

  useEffect(() => {
    async function fetchBaronaData() {
      const url = 'https://jobs.barona.fi/api/v2/ext/jobs?employerCompanyName=Restel';
      let response = await fetch(url);
      return await response.json();
    }
    const baronaPromise = fetchBaronaData();
    baronaPromise.then((raxBaronaData) => {
      const baJobs = raxBaronaData.jobs.filter(baronaJob => { return R.path(['title'], baronaJob).toLowerCase().includes(process.env.GATSBY_BRAND.toLowerCase().replace('-',' ') + ' ')});
      setBaronaJobs(baJobs);
    });
  }, []);

  const { pageContent, allArticles, allRestaurants, allEvents, brandSettings, allInternalReleases, allFeedBaronaRestel } = data;

  const menuParentPage = R.path(['hasMenu'], pageContext);

  const brandSTT = R.path(['nodes', 0, 'releases'], allInternalReleases);

  const renamedBrandSTT = brandSTT.map(function (node) {
    return {
      id: node.alternative_id,
      published: node.published,
      paakuva: node.mainImage ? node.mainImage.thumbnail_16_9 : null,
      sisalto: {
        sisalto: node.leadTextOrBodyStrip,
      },
      sivunOtsikko: node.title,
      sivupolku: slugify(node.title, { remove: /[*+~.()/'"!:@]/g, lower: true }),
      avainsanat: node.keywords
    };
  });
  const contentfulArticles = R.path(['nodes'], allArticles).map(node => {
    return {
      ...node,
      published: node.published2 ? node.published2 : node.published,
    };
  })

  const mergedArticles = contentfulArticles.concat(renamedBrandSTT).sort((a, b) => (a.published < b.published) ? 1 : -1);

  const slug = R.path(['sivupolku'], pageContent);
  const title = R.path(['sivunOtsikko'], pageContent);
  const vr360 = R.path(['vr360'], pageContent);
  const references = R.path(['dynaaminenSisalto'], pageContent);
  let heroData = { otsikko: title }, fullWidth = false;
  if(references){
    if (R.path(['dynaaminenSisalto'], pageContent).find(n => n.__typename === 'ContentfulPaanosto')) {
      heroData = R.path(['dynaaminenSisalto'], pageContent).find(n => n.__typename === 'ContentfulPaanosto');
    }
    if (menuParentPage || R.path(['dynaaminenSisalto'], pageContent).find(n => n.__typename === 'ContentfulRavintolalistaus')) {
      fullWidth = true;
    }
  }
  const hero = {
    image: R.path(['paakuva', 'fluid'], pageContent),
    mobileImage: R.path(['paakuvaMobiili', 'fluid'], pageContent),
    title: R.path(['otsikko'], heroData),
    titleColor: R.path(['sivunOtsikonVari'], pageContent) ? R.path(['sivunOtsikonVari'], pageContent) : null,
    content: R.path(['sisaltoteksti', 'sisaltoteksti'], heroData),
    ctaVisible: R.path(['naytaNavigointinappi'], heroData),
    ctaText: R.path(['linkkiteksti'], heroData),
    ctaLink: R.path(['linkkiosoite'], heroData)
  }
  
  const cuisine = R.path(['ravintolanTyyppi'], brandSettings)
  const seoTitle = R.path(['seoOtsikko'], pageContent)
  const seoDesc = R.path(['seoKuvaus', 'seoKuvaus'], pageContent)
  const someTitle = R.path(['someOtsikko'], pageContent) ? R.path(['someOtsikko'], pageContent) : seoTitle
  const someDesc = R.path(['someKuvaus', 'someKuvaus'], pageContent) ? R.path(['someKuvaus', 'someKuvaus'], pageContent) : seoDesc
  const metaImage = R.path(['paakuva', 'file', 'url'], pageContent)
  const pageURL = location.href
  const goal = R.path(['sivunTavoite'], pageContent)
  const service = R.path(['liiketoimintaAlue'], pageContent)
  const asiakaspolku = R.path(['asiakaspolku'], pageContent)

  return (
    <Layout>
      <SEO
        sitename={R.path(['nimi'], brandSettings)}
        seoTitle={seoTitle}
        seoDesc={seoDesc}
        someTitle={someTitle}
        someDesc={someDesc}
        image={metaImage}
        url={pageURL}
        pageTemplate="yleissivu"
        dataLayer={true}
        service={service}
        cuisine={cuisine}
        city="Helsinki"
        goal={goal}
        asiakaspolku={asiakaspolku}
      />
      {!menuParentPage && (hero.image || hero.title || hero.content) && <Hero data={hero} />}
      {fullWidth && 
        <main>
          <ReferenceHandler
            slug={slug}
            posts={references}
            articles={mergedArticles}
            restaurants={allRestaurants}
            events={allEvents}
            parentHero={hero}
            brandSettings={brandSettings}
            location={location}
          />
          { vr360 && 
            vr360.map(item => 
            
              <Grid container spacing={3}>
                <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <div dangerouslySetInnerHTML={createMarkup(item)} />
                </Grid>
                <Grid item xs={1}></Grid>
                </Grid>
                )
          }
        </main>
      }
      {!fullWidth && <Container maxWidth="lg">
        <main>
          <ReferenceHandler
            slug={slug}
            posts={references}
            articles={mergedArticles}
            restaurants={allRestaurants}
            events={allEvents}
            parentHero={hero}
            brandSettings={brandSettings}
            location={location}
            jobs={baronaJobs}
          />
          {vr360 && 
            vr360.map(item => <div dangerouslySetInnerHTML={createMarkup(item)} />)
          }
        </main>
      </Container>}
    </Layout>
  );
};

export default RegularPage;

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  fragment lunchFields on ContentfulRuokaAnnos {
    id
    ruokaAnnoksenNimi
    ruokaAnnoksenKuva {
      fluid(quality: 80, maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    ruokaAnnoksenKuvaus {
      ruokaAnnoksenKuvaus
    }
    ruokaAnnoksenHinta
    isonAnnoksenHinta
    pienenAnnoksenHinta
  }
  query regularPageQuery($slug: String!, $brand: String!) {
    allArticles: allContentfulUutissivu(filter: {ketju: {sivupolku: { eq: $brand }}}) {
      nodes {
        id
        published: createdAt
        sivunOtsikko
        sivupolku
        sisalto {
          sisalto
        }
        paakuva {
          fluid(quality: 60, maxHeight: 235) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        paakuvaMobiili {
          fluid(quality: 60, maxHeight: 250) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        published2: julkaisupaiva
        avainsanat
      }
    }
    allRestaurants: allContentfulRavintola(filter: {ketju: {sivupolku: { eq: $brand }}}) {
      nodes {
        id
        name: naviOtsikko
        slug: sivupolku
        cobaID: cobaId
        freeText: vapaaTeksti {
          freeText: vapaaTeksti
        }
      }
    }
    allEvents: allContentfulTapahtuma(filter: {ketju: {sivupolku: { eq: $brand }}}) {
      nodes {
        id
        ravintola {
          id
          name: naviOtsikko
          slug: sivupolku
        }
        toistuvaTapahtuma
        toistuvanTapahtumanEtuteksti
        nimi
        ajankohta
        kuvaus {
          kuvaus
        }
      }
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
      ravintoloidenYlasivu {
        sivupolku
      }
      uutistenYlasivu {
        sivupolku
      }
      ravintolanTyyppi
    }
    pageContent: contentfulYleissivu(ketju: {sivupolku: { eq: $brand }}, sivupolku: { eq: $slug }) {
      id
      sivupolku
      createdAt
      sivunOtsikko
      sivunOtsikonVari
      seoOtsikko
      seoKuvaus {
        seoKuvaus
      }
      someOtsikko
      someKuvaus {
        someKuvaus
      }
      vr360
      paakuva {
        fluid(quality: 60, maxWidth: 1680) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      paakuvaMobiili {
        fluid(quality: 60, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      dynaaminenSisalto {
        ... on Node {
          ... on ContentfulRavintolalistaus {
            id
          }
          ... on ContentfulTapahtumalistaus {
            id
          }
          ... on ContentfulUutislistaus {
            id
            otsikko
            avainsanat
          }
          ... on ContentfulAvoimetTyopaikat {
            id
            otsikko
            sisaltoteksti {
              sisaltoteksti
            }
          }
          ... on ContentfulMenu {
            id
            menuKategoriat {
              id
              kategorianNimi
              kategorianKuvaus {
                kategorianKuvaus
              }
              kategorianKuva {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              piilotaHinnat
              ruokaAnnokset {
                ... on Node {
                  ... on ContentfulRuokaAnnos {
                    id
                    __typename
                    ruokaAnnoksenNimi
                    ruokaAnnoksenKuva {
                      fluid(quality: 60, maxWidth: 400) {
                        ...GatsbyContentfulFluid_withWebp
                      }
                    }
                    ruokaAnnoksenKuvaus {
                      ruokaAnnoksenKuvaus
                    }
                    ruokaAnnoksenHinta
                    isonAnnoksenHinta
                    pienenAnnoksenHinta
                    hintahaitari
                  }
                  ... on ContentfulTeksti {
                    id
                    __typename
                    otsikko
                  }
                }
              }
              kategorianAlateksti {
                kategorianAlateksti
              }
            }
          }
          ... on ContentfulLounaslista {
            id
            kuvausteksti {
              kuvausteksti
            }
            voimassaolo
            voimassaolovuosi

            ravintola {
              ... on Node {
                ... on ContentfulRavintola {
                  id
                  name: naviOtsikko
                }
              }
            }

            maanantai {
              ... on Node {
                ... on ContentfulRuokaAnnos {
                  ... lunchFields
                }
              }
            }
            tiistai {
              ... on Node {
                ... on ContentfulRuokaAnnos {
                  ... lunchFields
                }
              }
            }
            keskiviikko {
              ... on Node {
                ... on ContentfulRuokaAnnos {
                  ... lunchFields
                }
              }
            }
            torstai {
              ... on Node {
                ... on ContentfulRuokaAnnos {
                  ... lunchFields
                }
              }
            }
            perjantai {
              ... on Node {
                ... on ContentfulRuokaAnnos {
                  ... lunchFields
                }
              }
            }

          }
          ... on ContentfulPaanosto {
            id
            otsikko
            linkkiosoite
            linkkiteksti
            naytaNavigointinappi
            sisaltoteksti {
              sisaltoteksti
            }
          }
          ... on ContentfulTeksti {
            id
            otsikko
            ingressi {
              ingressi
            }
            sisalto {
              sisalto
            }
          }
          ... on ContentfulKampanjanosto {
            id
            nimi
            kampanjat {
              ... on Node {
                ... on ContentfulKampanjalinkki {
                  id
                  otsikko
                  kuvausTeksti {
                    kuvausTeksti
                  }
                  kuva {
                    fluid(quality: 60, maxWidth: 1100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  linkki
                  linkinTeksti
                  linkinKohde
                  kakkoslinkki
                  kakkoslinkinTeksti
                  kakkoslinkinKohde
                  kampanjanostonKoko
                }
                ... on ContentfulYleissivu {
                  __typename
                  id
                  otsikko : sivunOtsikko
                  sivupolku
                  kuvausTeksti : kuvausteksti {
                    kuvausTeksti : kuvausteksti
                  }
                  kuva: paakuva {
                    fluid(quality: 60, maxWidth: 1100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  kampanjanostonKoko
                }
              }
            }
          }
          ... on ContentfulVuorottelevatNostot {
            __typename
            id
            otsikko
            kuvaus {
              kuvaus
            }
            kampanjat {
              ... on ContentfulKampanjalinkki {
                id
                otsikko
                kuvausTeksti {
                  kuvausTeksti
                }
                kuva {
                  fluid(quality: 60, maxWidth: 900) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                linkinKohde
                linkinTeksti
              }
              ... on ContentfulYleissivu {
                __typename
                id
                otsikko : sivunOtsikko
                sivupolku
                kuvausteksti {
                  kuvausteksti
                }
                kuva: paakuva {
                  fluid(quality: 60, maxWidth: 900) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      sivunTavoite
      liiketoimintaAlue
      asiakaspolku
    }
    allInternalReleases(filter: {id: {ne: "dummy"}}) {
      nodes {
        releases {
          alternative_id
          mainImage {
            url
            thumbnail_16_9
          }
          published
          title
          leadTextOrBodyStrip
          body
          keywords
        }
      }
    }
    #allFeedBaronaRestel {
    #  nodes {
    #    id
    #    title
    #    startDate: isoDate
    #    categories
    #    link
    #    location
    #    contentSnippet
    #    content {
    #      encoded
    #    }
    #  }
    #}
  }
`;


